import { Carro } from './../../models/carro.model';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { StorageService } from '../../services/storage.service';
import { cantidadesService } from "../../services/cantidades.service";

import { CartService } from 'src/app/services/cart.service';
import { ApiService } from '../../api.service';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  cartProducts: Carro;
  total = 0;
  resultado = '';
  nophoto = '';
  texto = '';

  constructor(  public global: GlobalService, 
                public ctdadService: cantidadesService,
                public alertController: AlertController, 
                public modalController: ModalController, 
                public cartService: CartService, 
                public apiService: ApiService) { }

  ngOnInit() {
    this.getCartItems();
    this.nophoto = this.global.url + 'imagenes/NOFOTO.JPG';
  }

  // Get Cart Items From Storage
  getCartItems() 
  {
    this.cartService.cart().then(async (products) => {
      this.cartProducts = products;
      this.global.cartProducts = products;
      console.log(this.cartProducts);
      const object = this.cartProducts.articulos;
      for (let i = 0; i < object.length; i++) 
      {
        console.log(object[i]);
        object[i] = await this.apiService.dameUnidades(object[i]);
        object[i].modificado = false;

        if( (object[i].depeso == "S" ) || (object[i].unidadvta == "B" ) )
            object[i].kilos = object[i].unid2 * object[i].peso;
      }
      
      console.log('Articulos carro: ' + object);
      this.global.artcart = Object.keys(object).length;
    });
  }
  dametexto(item){
  this.texto = this.apiService.dametexto(item);
  if(this.texto ==  'Unidades'){
    return('Und.');
  }else if(this.texto ==  'Cajas'){
    return('Caj.');
  }else{
    return('Und.');
  }
  }

    async inputChange(item, unid, change: number) 
    {
        if( unid == 1 )
            await this.ctdadService.inputQtySegunUnid1( item, change );
        else
            await this.ctdadService.inputQtySegunUnid2( item, change );

    }


    async actualizaProducto(item)
    {
        this.resultado = await this.apiService.cartAdd(item);        
        this.getCartItems();         
    }
  
    async changeQty(item, unid, change: number)
    {
        if( unid == 1 )
            await this.ctdadService.changeQtySegunUnid1( item, change );
        else
            await this.ctdadService.changeQtySegunUnid2( item, change );
    }

  // Remove Product From Cart
  async removeProduct(product, index) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Quitar artículo',
      message: '¿Desea quitar este artículo del pedido?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Cancelado');
          }
        }, {
          text: 'Quitar',
          handler: () => {
            this.apiService.cartDel(product.codigo.replace(/ /g, ''));
            console.log('Confirmado');
            setTimeout(() => {
              this.getCartItems();
            }, 150);
          }
        }
      ]
    });

    await alert.present();
  }

  onError(item) {
    console.log(item.imagen);
    item.imagen = this.nophoto;
  }
  // Go to checkout page
  goToCheckout() {

    this.alertController.create({
      header: 'Realizar pedido',
      subHeader: 'Confime para continuar',
      message: '¿Está seguro de realizar este pedido?',
      buttons: [
        {
          text: 'Si',
          handler: () => {
            console.log('ha dicho que si, seguimos con el pedido');
            this.pedido();
          }
        },
        {
          text: 'No',
          handler: () => {
            console.log('El pedido queda aún como borrador');
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  async pedido() {
    // this.dismiss();
    await this.apiService.cartClose().then((resultado) => {

      console.log("Aqui resultado"  + resultado);
      console.log(resultado);
      if (resultado == '202') {
          this.dismiss();
          this.global.artcart = 0;
          this.getCartItems();
          this.presentAlert('Pedido realizado correctamente.');
        } else {
          this.presentAlert('Error cerrando el pedido. Revise la conexión e intentelo de nuevo mas tarde.');
        }
    });
    
  }


  // Go to product details page
  async goToProductDetails(item1) {
    // console.log(codigoP);
    const modal = await this.modalController.create({
      component: ProductDetailsComponent,
      componentProps: { producto: item1 }
    });
    return await modal.present();
  }

  async presentAlert(err) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.global.titulo,
      message: err,
      buttons: ['OK']
    });

    await alert.present();
  }

  // Back to previous page options
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
