import { Component, OnInit, Input } from "@angular/core";
import { CartComponent } from "../cart/cart.component";
import { ModalController, ToastController } from "@ionic/angular";
import { Product } from "../../models/product.model";
import { ProductsService } from "../../services/products.service";
import { GlobalService } from "../../services/global.service";
import { CartService } from "./../../services/cart.service";
import { ApiService } from "../../api.service";
import { Carro } from "./../../models/carro.model";
import { cantidadesService } from "../../services/cantidades.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
    selector: "app-product-details",
    templateUrl: "./product-details.component.html",
    styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
    @Input() producto: any;
    codigo;
    //products: Product[];
    resultado = "";
    items: Product[];
    cartProducts: Carro;
    nophoto = "";

    // Slider Options
    slideOpts = {
        initialSlide: 0,
        loop: false,
        autoplay: false,
        speed: 400,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            dynamicBullets: true,
        },
    };

    constructor(    private cartService: CartService, 
                    private router: Router,
                    public ctdadService: cantidadesService,
                    public global: GlobalService, 
                    public productsService: ProductsService, 
                    public modalController: ModalController, 
                    public apiService: ApiService, 
                    public toastController: ToastController) {}

    ngOnInit() {
        console.log(this.producto);        
        this.ctdadService.calculaKilos(this.producto);
        //this.getProductList(this.codigo);
        this.nophoto = this.global.url + "imagenes/NOFOTO.JPG";
        this.getProductRel(this.producto.codigo);
        
    }
    

    getProductRel(codigo) {
        console.log(codigo);
        this.productsService.productRel(codigo).then((products) => {
            this.items = products;
            console.log(this.items);
           
        });
    }
    onError(item) {
        console.log(item.imagen);
        item.imagen = this.nophoto;
    }
    // Go to product details page
    async goToProductDetails(item1) {
    
        this.dismiss();
        const modal = await this.modalController.create({
            component: ProductDetailsComponent,
            componentProps: { producto: item1 },
        });
        return await modal.present();
    }

    // Go to cart page
    async gotoCartPage() {
        this.dismiss();
        const modal = await this.modalController.create({
            component: CartComponent,
        });
        
        return await modal.present();
    }

    // Añadir a favoritos
    async FavAdd(articulo, e) {
        e.stopPropagation();
        this.resultado = await this.apiService.FavAdd(articulo.codigo);
        articulo.isWishlist = true;
        this.global.cambio = true;
    }

    // Quitar de favoritos
    async FavDel(articulo, e) {
        e.stopPropagation();
        this.resultado = await this.apiService.FavDel(articulo.codigo);
        articulo.isWishlist = false;
        this.global.cambio = true;
    }



    
    async addtocart(item) {
        console.log(item);
        this.resultado = await this.apiService.cartAdd(item);
        console.log(this.resultado);

        this.getCartItems(item.nombre);
    }

    async compartir(item1, e) {
        e.stopPropagation();
        console.log("chat");
        console.log(item1);
        this.global.itemChat = item1;
        this.router.navigate(["/tabs/tab2"]);
    }

    async getCartItems(nombre) {
        this.cartService.cart().then((products) => {
            this.cartProducts = products;
            const object = this.cartProducts.articulos;
            console.log("Articulos carro: " + object);
            this.global.artcart = Object.keys(object).length;
        });
        const toast = await this.toastController.create({
            message: nombre + " añadido a tu carrito.",
            duration: 2000,
        });
        toast.present();
    }

    // Back to previous page function
    dismiss() {
        this.modalController.dismiss({
            dismissed: true,
        });
    }
}
