import { Injectable } from '@angular/core';
import { __values } from 'tslib';

@Injectable({
    providedIn: 'root'
})
export class cantidadesService {

    constructor() {
    }

    muestroUnid1(item) {
        if (item.depeso == "S") //->Si el artículo es de peso
            return true;

        if (item.unidadvta == "B") //->Si el artículo solo se vende por bultos, jamás debemos calcular unidad 1 en base a la 2
            return true;


        switch (item.formaRedondeo) {
            case 1:  //1 - Solo pide unidad 1 y recalcula unidad 2 (Se muestran las unidades 2 y se recalculan, pero no se pueden modificar)
                {
                    return false;
                }
            case 2: //2 - Solicitará las dos unidades de medida, recalculando siempre en base a la introducida, aplicando dos decimales en el cálculo (el redondeo alpicado es el normal).
                {
                    return false;
                }
            case 3: //3 - No existe relación, es decir, no se realizará el recálculo automático de unidades. Valido para  definir artículos con peso.                    
                // En el caso de APP IGUALAMOS 
                {
                    return false;
                }

            case 4: //4 - Al realizar el recálculo de unidades, en caso de que  se aplique el recalculo sobre las unidades 1,  se redeondeara al entero superiro (4,3 -> 5).
                {
                    return false;
                }
            case 5: //5 - Solo pide unidades 2, las unidades 1 quedan ocultas y a valor 0.
                {
                    return false;
                }
            case 6: //6 - Al igual que el 2, pero con el numero de decimales indicados en el valor del parametro 74.
                {
                    return false;
                }
            case 7: //7 - No existe relación, pero utilizando el campo "cajas virtuales", consultar parámetros.
                {
                    return false;
                }
            case 8: //8 - Recalculamos las unidades obviando los decimales, es decir, se recalcula igual que la opcion 2, pero este no muestra los decimales (simplemente nos quedamos con la parte entera)
                {
                    return false;
                }

            default:
                {
                    return false;
                }
        }
    }

    muestroUnid2(item) {
        if (item.depeso == "S") //->Si el artículo es de peso
            return false;

        if (item.unidadvta == "B") //->Si el artículo solo se vende por bultos, jamás debemos calcular unidad 1 en base a la 2
            return false;


        switch (item.formaRedondeo) {
            case 1:  //1 - Solo pide unidad 1 y recalcula unidad 2 (Se muestran las unidades 2 y se recalculan, pero no se pueden modificar)
                {
                    return false;
                }
            case 2: //2 - Solicitará las dos unidades de medida, recalculando siempre en base a la introducida, aplicando dos decimales en el cálculo (el redondeo alpicado es el normal).
                {
                    return true;
                }
            case 3: //3 - No existe relación, es decir, no se realizará el recálculo automático de unidades. Valido para  definir artículos con peso.                    
                // En el caso de APP IGUALAMOS 
                {
                    return false;
                }

            case 4: //4 - Al realizar el recálculo de unidades, en caso de que  se aplique el recalculo sobre las unidades 1,  se redeondeara al entero superiro (4,3 -> 5).
                {
                    return true;
                }
            case 5: //5 - Solo pide unidades 2, las unidades 1 quedan ocultas y a valor 0.
                {
                    return true;
                }
            case 6: //6 - Al igual que el 2, pero con el numero de decimales indicados en el valor del parametro 74.
                {
                    return true;
                }
            case 7: //7 - No existe relación, pero utilizando el campo "cajas virtuales", consultar parámetros.
                {
                    return true;
                }
            case 8: //8 - Recalculamos las unidades obviando los decimales, es decir, se recalcula igual que la opcion 2, pero este no muestra los decimales (simplemente nos quedamos con la parte entera)
                {
                    return true;
                }

            default:
                {
                    return true;
                }
        }
    }

    async changeQtySegunUnid1(item, change: number) {
        console.log("segun unid1", item);

        setTimeout(() => {

            item.unid1 = this.incrementa(Number(item.unid1), change);
            this.calculaUnid2(item);
            console.log(item);
        }, 5);
    }

    async changeQtySegunUnid2(item, change: number) {
        console.log("segun unid2", item);

        setTimeout(() => {

            item.unid2 = this.incrementa(Number(item.unid2), change);
            this.calculaUnid1(item);
            console.log(item);
        }, 5);
    }

    async inputQtySegunUnid1(item, ev: any) {
        console.log("segun unid1", item);

        setTimeout(() => {

            item.unid1 = ev.target.value;
            this.calculaUnid2(item);
            console.log(item);
        }, 5);
    }

    async inputQtySegunUnid2(item, ev: any) {
        console.log("segun unid2", item);

        setTimeout(() => {

            item.unid2 = ev.target.value;
            this.calculaUnid1(item);
            console.log(item);
        }, 5);
    }

    async inputQtySegunMiniaturas(item, ev: any) {

        setTimeout(() => {

            if ((item.unidadvta == 'B') || (item.depeso == 'S')) ///-> Si el artículo se vende por bultos o es de peso
            {
                item.unid1 =  ev.target.value;
                this.calculaUnid2(item);
            }
            else {
                item.unid2 =  ev.target.value;
                this.calculaUnid1(item);
            }

            console.log(item);
        }, 5);
    }

    async changeQtySegunMiniaturas(item, change: number) {

        setTimeout(() => {

            if ((item.unidadvta == 'B') || (item.depeso == 'S')) ///-> Si el artículo se vende por bultos o es de peso
            {
                item.unid1 = this.incrementa(Number(item.unid1), change);
                this.calculaUnid2(item);
            }
            else {
                item.unid2 = this.incrementa(Number(item.unid2), change);
                this.calculaUnid1(item);
            }

            console.log(item);
        }, 5);
    }

    muestroUnid1Miniatura(item) {
        return ((item.unidadvta == 'B') || (item.depeso == 'S'));
    }

    muestroUnid2Miniatura(item) {
        return !((item.unidadvta == 'B') || (item.depeso == 'S'));
    }

    dameTextoMiniatura(item) {
        if ((item.unidadvta != "B")&&(item.depeso != "S")) {
            if ((item.medi2 !== '') && (item.medi2 !== 'unid2')) {
                return item.medi2;
            }
            else {
                return 'Unidades';
            }
        }
        else {
            if ((item.medi1 !== '') && (item.medi1 !== 'unid1')) {
                return item.medi1;
            }
            else {
                return 'Cajas';
            }
        }
    }

    muestroKg(item) {        
        return  ((item.depeso == "S")||(item.unidadvta == "B"));
    }


    calculaKilos(item)
    {
        item.kilos = this.redondearNumero(item.unid2 * item.peso,2);
    }

    incrementa(valor, cantidad) {
        if ((valor < 1) && (cantidad < 0))
            return valor;

        return valor + cantidad;
    }

    redondearNumero(numero: number, decimales): number {
        let operacion = Math.pow(10, decimales);
        return Math.round(numero * operacion) / operacion;
    }    

    calculaUnid1(item) {
        item.modificado = true;
        if (item.depeso == "S") //->Si el artículo es de peso
        {
            return;
        }

        if (item.unidadvta == "B") //->Si el artículo solo se vende por bultos, jamás debemos calcular unidad 1 en base a la 2
        {
            return;
        }

        switch (item.formaRedondeo) {
            case 1:  //1 - Solo pide unidad 1 y recalcula unidad 2 (Se muestran las unidades 2 y se recalculan, pero no se pueden modificar)
                {
                    //En esta opción, nunca calculamos unid1
                    break;
                }
            case 2: //2 - Solicitará las dos unidades de medida, recalculando siempre en base a la introducida, aplicando dos decimales en el cálculo (el redondeo alpicado es el normal).
                {
                    item.unid1 = this.redondearNumero(item.unid2 / this.dameRelacionUnidades(item.relacionUnidades), 2);
                    break;
                }
            case 3: //3 - No existe relación, es decir, no se realizará el recálculo automático de unidades. Valido para  definir artículos con peso.                    
                // En el caso de APP IGUALAMOS 
                {
                    item.unid1 = item.unid2;
                    break;
                }

            case 4: //4 - Al realizar el recálculo de unidades, en caso de que  se aplique el recalculo sobre las unidades 1,  se redeondeara al entero superiro (4,3 -> 5).
                {
                    item.unid1 = Math.ceil(item.unid2 / this.dameRelacionUnidades(item.relacionUnidades));
                    break;
                }
            case 5: //5 - Solo pide unidades 2, las unidades 1 quedan ocultas y a valor 0.
                {
                    break;
                }
            case 6: //6 - Al igual que el 2, pero con el numero de decimales indicados en el valor del parametro 74.
                {
                    item.unid1 = this.redondearNumero(item.unid2 / this.dameRelacionUnidades(item.relacionUnidades), 2);
                    break;
                }
            case 7: //7 - No existe relación, pero utilizando el campo "cajas virtuales", consultar parámetros.
                {
                    //statements; 
                    break;
                }
            case 8: //8 - Recalculamos las unidades obviando los decimales, es decir, se recalcula igual que la opcion 2, pero este no muestra los decimales (simplemente nos quedamos con la parte entera)
                {
                    item.unid1 = this.redondearNumero(item.unid2 / this.dameRelacionUnidades(item.relacionUnidades), 2);
                    break;
                }

            default:
                {
                    item.unid1 = item.unid2;
                    break;
                }
        }
    }

    calculaUnid2(item) {
        item.modificado = true;
        if (item.depeso == "S") //->Si el artículo es de peso
        {
            item.unid2 = item.unid1;
            item.kilos = this.redondearNumero(item.unid2 * item.peso,2);
            return;
        }

        if (item.unidadvta == "B") //->Si el artículo solo se vende por bultos, siempre igualamos
        {
            item.unid2 = item.unid1;
            item.kilos = this.redondearNumero(item.unid2 * item.peso,2);
            return;
        }


        switch (item.formaRedondeo) {
            case 1:  //1 - Solo pide unidad 1 y recalcula unidad 2 (Se muestran las unidades 2 y se recalculan, pero no se pueden modificar)
                {
                    item.unid2 = item.unid1 * this.dameRelacionUnidades(item.relacionUnidades);
                    break;
                }
            case 2: //2 - Solicitará las dos unidades de medida, recalculando siempre en base a la introducida, aplicando dos decimales en el cálculo (el redondeo alpicado es el normal).
                {
                    item.unid2 = item.unid1 * this.dameRelacionUnidades(item.relacionUnidades);
                    break;
                }
            case 3: //3 - No existe relación, es decir, no se realizará el recálculo automático de unidades. Valido para  definir artículos con peso.                                        
                // En el caso de APP IGUALAMOS 
                {
                    item.unid2 = item.unid1;
                    break;
                }

            case 4: //4 - Al realizar el recálculo de unidades, en caso de que  se aplique el recalculo sobre las unidades 1,  se redeondeara al entero superiro (4,3 -> 5).
                {
                    item.unid2 = item.unid1 * this.dameRelacionUnidades(item.relacionUnidades);
                    break;
                }
            case 5: //5 - Solo pide unidades 2, las unidades 1 quedan ocultas y a valor 0.
                {
                    break;
                }
            case 6: //6 - Al igual que el 2, pero con el numero de decimales indicados en el valor del parametro 74.
                {
                    item.unid2 = item.unid1 * this.dameRelacionUnidades(item.relacionUnidades);
                    break;
                }
            case 7: //7 - No existe relación, pero utilizando el campo "cajas virtuales", consultar parámetros.
                {
                    //statements; 
                    break;
                }
            case 8: //8 - Recalculamos las unidades obviando los decimales, es decir, se recalcula igual que la opcion 2, pero este no muestra los decimales (simplemente nos quedamos con la parte entera)
                {
                    item.unid2 = item.unid1 * this.dameRelacionUnidades(item.relacionUnidades);
                    break;
                }

            default:
                {
                    item.unid2 = item.unid1;
                    break;
                }
        }
    }

    dameRelacionUnidades(relacion) {
        try {
            let rela = Number(relacion);

            if (rela < 1) return 1;

            return rela;
        }
        catch
        {
            return 1;
        }

    }
}
